<template>
  <div class="campaigns">
    <div class="new-campaign">
      <div class="container">
        <div class="header-wrap">
          <h2>{{ $t("campaigns.create") }}</h2>
          <p class="lead">{{ $t("campaigns.createInfo") }}</p>
        </div>

        <div class="creation-wrap">
          <form @submit="create_campaign" class="form" method="post">
            <ul class="cf">
              <li class="active"><span class="yellow">1</span>{{ $t("campaigns.step1") }}</li>
              <li><span>2</span></li>
              <li><span>3</span></li>
            </ul>

            <!-- <div v-if="user.payment_methods">
              <div v-if="user.payment_methods.length == 0">
                <div class="alert alert-danger cf">
                  <p style="margin: 0">
                    {{ $t("global.addPaymentStart") }}
                    <router-link to="/balance/setup_intents/new">{{ $t("global.addPaymentMethod") }}</router-link>
                    {{ $t("global.addPaymentEnd") }}.
                  </p>
                </div>
              </div> -->
              <div>
                <div class="creation-block">
                  <h3>{{ $t("campaigns.name") }}</h3>
                  <p>{{ $t("campaigns.nameInfo") }}</p>
                  <div class="form-wrap">
                    <input v-model="campaign.name" class="form-input" type="text" name="name" id="name" :placeholder="$t('global.name')" />
                  </div>
                </div>

                <div class="creation-block">
                  <h3>{{ $t("campaigns.industry") }}</h3>
                  <p>{{ $t("campaigns.industryInfo") }}</p>
                  <div class="form-wrap select-wrap">
                    <label for="industry">{{ $t("campaigns.industryMain") }}</label>
                    <select v-model="industry" v-on:change="onChange" class="select-wrap">
                      <option v-for="ind in industries" v-bind:value="ind.id" v-bind:key="ind.id">
                        {{ ind.name }}
                      </option>
                    </select>
                  </div>

                  <div class="form-wrap select-wrap" v-if="industry">
                    <label for="subIndustry">{{ $t("campaigns.industrySub") }}</label>
                    <select v-model="subIndustry" class="select-wrap">
                      <option v-for="sub in subIndustries" v-bind:value="sub.id" v-bind:key="sub.id">
                        {{ sub.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="optional-features">
                  <div v-if="optional_features" class="optional-yes">
                    <h3>
                      <span class="material-icons">settings</span><span>{{ $t("campaigns.settings") }}</span>
                    </h3>
                    <p>{{ $t("campaigns.settingsInfo") }}</p>

                    <div class="creation-block">
                      <h3>{{ $t("campaigns.period") }}</h3>
                      <p>{{ $t("campaigns.periodInfo") }}</p>
                      <div class="row row-gutter-20">
                        <div class="col-12">
                          <div class="form-wrap" style="margin: 8px 0 0 0">
                            <label for="start_date">{{ $t("campaigns.startDate") }}</label>
                            <input v-model="campaign.start_date" class="form-input" type="date" name="start_date" id="start_date" />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-wrap" style="margin: 8px 0 0 0">
                            <label for="end_date">{{ $t("campaigns.endDate") }}</label>
                            <input v-model="campaign.end_date" class="form-input" type="date" name="end_date" id="end_date" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="creation-block" style="margin-bottom: 0">
                      <h3>{{ $t("campaigns.periodTime") }}</h3>
                      <p>{{ $t("campaigns.periodTimeInfo") }}</p>
                      <div class="row row-gutter-20">
                        <div class="col-12">
                          <div class="form-wrap" style="margin: 15px 0 0 0">
                            <label for="start_time">{{ $t("campaigns.periodStart") }}</label>
                            <input v-model="campaign.start_time" class="form-input" type="time" name="start_time" id="start_time" />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-wrap" style="margin: 15px 0 0 0">
                            <label for="end_time">{{ $t("campaigns.periodEnd") }}</label>
                            <input v-model="campaign.end_time" class="form-input" type="time" name="end_time" id="end_time" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <!--
                  <div v-else>
                    <p @click="enable_expert_mode" class="button button-grey button-100 button-small" style="margin: 0 0 20px 0">
                      <span class="material-icons">settings</span><span>{{ $t("campaigns.openSettings") }}</span>
                    </p>
                  </div>
                  -->
                </div>

                <p>
                  <input type="submit" :value="$t('campaigns.toStep2')" class="button button-ci button-round button-100" />
                </p>
              </div>
            <!-- </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";

export default {
  name: "new_campaign",
  data() {
    return {
      errors: [],
      industries: [],
      industry: "",
      subIndustries: [],
      subIndustry: "",
      campaign: {
        name: "",
        industry_id: "",
      },
      optional_features: true,
      user: {},
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.user = response.data.user;
        });

      this.get_industries(1);
    },
    get_industries(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/industries?page=" + page, {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.industries = response.data.industries;

          if (response.data.meta.pagination.links.next) {
            this.get_industries(response.data.meta.pagination.links.next.split("=")[1]);
          }
        });
    },
    fetchCampaigns() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/campaigns", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.instances = response.data.instances;
        });
    },
    create_campaign: function (e) {
      e.preventDefault();
      this.campaign.industry_id = this.subIndustry;

      axios
        .post(process.env.VUE_APP_BASE_API + "/campaigns", this.campaign, {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.$router.push("/campaigns/" + response.data.campaign.uuid);
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    enable_expert_mode() {
      this.campaign.start_date = new Date().toISOString().substring(0, 10);
      var aYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear(), new Date().getMonth() + 1));
      this.campaign.end_date = aYearFromNow.toISOString().substring(0, 10);
      this.campaign.start_time = "06:00";
      this.campaign.end_time = "22:00";
      this.optional_features = true;
    },
    onChange: function () {
      this.subIndustries = this.industries.find((x) => x.id === this.industry).industries;
    },
  },
  mounted() {
    this.get_data();
    this.fetchCampaigns();
    this.enable_expert_mode();
  },
};
</script>

<style lang="scss"></style>
